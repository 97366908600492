import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/templates/single-page.js";
import { graphql } from "gatsby";
import Caption from "../components/caption";
export const data = graphql`
  query {
    file(relativePath: { eq: "anastasia.jpg" }) {
      childImageSharp {
        fixed(width: 364, quality: 60) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Img = makeShortcode("Img");
const layoutProps = {
  data,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Watchung Cooperative is proud to offer Music Together curriculum to our students and families. Music Together is a renowned early childhood music and movement program for children from birth through age eight—and the grownups who love them! Their music classes help little ones develop their innate musicality—and much, much more.`}</p>
    <p>{`The primary goal of Music Together is to support children's music development. However, their play-based, developmentally appropriate approach also fosters children's growth in other areas essential to success in both school and life, raising the quality of a child's early education both in class and at home. In other words, Music Learning Supports All Learning®.`}</p>
    <p>{`The Music Together curriculum supports children's language development and emergent literacy, social and emotional development, cognition and general knowledge, physical and motor development, and approaches to learning.`}</p>
    <hr></hr>
    <p>{`Concert flutist and musical educator Sophia Anastasia leads our Music Together enrichment classes at the Co-op. During her classes, Miss Sophia nurtures children's inner sense of musicality by exposing them to fundamental musical concepts in a fun and playful way - in keeping with the Co-op's philosophy of learning through play. `}</p>
    <Img fixed={props.data.file.childImageSharp.fixed} alt="Sophia Anastasia, Music Together Instructor" style={{
      margin: 'auto',
      marginTop: '1.75rem',
      display: 'block'
    }} mdxType="Img" />
    <Caption mdxType="Caption">Sophia Anastasia</Caption>
    <p>{`She uses songs, chants, and rhymes to introduce concepts including loud/soft, fast/slow, high/low, and pitch and rhythm matching. Every class also incorporates dance, and an exploration of various musical instruments. Classes also expose the children to many genres of music, including jazz, rock, folk and international. `}</p>
    <p>{`Miss Sophia has been teaching and performing music since she was a teen. She received a Bachelor's degree in Music Education at Montclair State University, specializing in early childhood music. After graduating, she continued her performance studies and received a Master's degree in Flute performance at the Manhattan School of Music and a Doctorate degree in Flute at SUNY Stony Brook. During her studies she regularly performed with various regional orchestras and chamber groups throughout the tri-state area. She became the founding member of the Electrum Flute and Percussion Duo and in 2015 released their second album titled "Thoughts."`}</p>
    <p>{`Giving birth to her three children changed her life in many ways. Miss Sophia shifted gears and decided to put a hold on her performing career, finding a new love ... her kids. Today, she feels as though she has come full circle, doing what she was trained to do so many years ago, spreading her love of music to young children once again. Miss Sophia maintains a private teaching studio in Montclair, and is also on faculty at Montclair State University Prep Center for the Arts.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      